import React, { useState } from "react";
import Select from "react-select";
import DatosQR from "./DatosQR";

const DatosxAdmitidos = ({
  serenos,
  setDocumento,documento, datos,setDatos,setSelectedFuncion, setDnivalido, dnivalido ,tiposControles, 
  setTipoControl,
}) => {
  const[showModalDatosQr , setShowModalDatosQr] = useState(false)
  return (
    <>
      <div
        className="mb-3 divselectpersona"
        style={{
          width: "80%",
          padding: "15px",
          justifyContent:'center',
          display:'none',
        }}
      >
        <button className="btn btn-success" style={{width:'70%',margin:'auto'}} onClick={()=> setShowModalDatosQr(true)}> Buscar por QR</button>
       
      </div>
      <div
        className="mb-3 divselectpersona"
        style={{
          width: "80%",
          padding: "15px",
        }}
      >
        <label className="form-label">Seleccione la persona:</label>
        <Select
          className="selectpersona"
          options={serenos.map((sereno) => ({
            value: sereno.SERE_P_chDNI,
            label: `( ${sereno.SERE_P_chDNI} ) ${sereno.SERE_APELLNOM}`,
          }))}
          onChange={(selectedOption) => setDocumento(selectedOption.value)}
          isSearchable
          placeholder="Buscar Personal"
        />
      </div>
      <div
        className="mb-3 divselectfuncion"
        style={{
          width: "80%",
          padding: "15px",
          paddingTop: "0px",
        }}
      >
        <label className="form-label">
          Seleccione Tipo de Control:
        </label>
        <Select
          className="selectfuncion bold"
          options={tiposControles.map((tipocontrol) => ({
            value: tipocontrol.param_CH_nomParam,
            label: tipocontrol.param_CH_nomParam,
          }))}
          onChange={(selectedOption) =>
            setTipoControl(
              selectedOption ? selectedOption.value : null
            )
          }
          isSearchable
          placeholder="Selecciona un Tipo"
        />
      </div>
      <DatosQR 
      showModalDatosQr={showModalDatosQr}
      onclose={()=>setShowModalDatosQr(false)}
      setDocumento={setDocumento}
      setDatos={setDatos}
      setSelectedFuncion={setSelectedFuncion}
      setDnivalido={setDnivalido}
      documento={documento}
      dnivalido={dnivalido}
      datos={datos}
      />
    </>
  );
};

export default DatosxAdmitidos;
