/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import axios from "axios";
import './Componentes/CSS/estilos.css'; 
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";  // Ensure that the import is correct
import IconoLogo from './Componentes/SVG/IconoLogo.svg'
function App() {
  const [dni, setDni] = useState("");
  const [rol, setRol] = useState("");
  const [clave, setClave] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [ip, setIp] = useState(null);
  const [fechaRol, setFechaRol] = useState(null);
  
  useEffect(() => {
    const fetchIp = async () => {
      try {
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();
        setIp(data.ip);
        console.log(data.ip)
        Cookies.set("miip", data.ip);

      } catch (error) {
        console.error('Error fetching IP:', error);
      }
    };

    fetchIp();
  }, []);
  useEffect(() => {
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString('es-ES');
    setFechaRol(formattedDate);
    console.log(formattedDate)
  }, []);
  const encryptPassword = (password) => {
    // Use a secret key for encryption
    const secretKey = "your-secret-key";
    const encryptedPassword = CryptoJS.AES.encrypt(password, secretKey).toString();
    return encryptedPassword;
  };
  const obtenerDatos = async () => {
    try {

      const respuesta = await axios.get(
        `https://api01.cloudlab.net.pe/pi124_M_MAES_SEREN_LOGIN/${dni}/${clave}/`
      );
      const rol = respuesta.data.SERE_chRol;
      const nombre = respuesta.data.SERE_chNOM;
      const apellidopat = respuesta.data.SERE_chAPEPAT;
      const apellidomat = respuesta.data.SERE_chAPEMAT;
      const dnilogin = respuesta.data.SERE_P_chDNI;
      const CONTRASENIA = respuesta.data.SERE_chCLAVE;
      const encryptedClave = encryptPassword(CONTRASENIA);

      var datos = {
        nombre: nombre,
        apellidopat: apellidopat,
        apellidomat: apellidomat,
        dnilogin: dnilogin,
        clave: encryptedClave
      };
      var datosString = JSON.stringify(datos);
      localStorage.setItem("datos", datosString);

      setRol(rol);
      Cookies.set("rol", rol);
      Cookies.set("nombre", nombre);
      Cookies.set("apellidopat", apellidopat);
      Cookies.set("apellidomat", apellidomat);
      Cookies.set("dnilogin", dnilogin);
      window.location.replace('/placa-registro');


    } catch (error) {
      console.error("Error al obtener los datos:", error);
      setErrorMessage("!Los datos ingresados son incorrectos");
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    obtenerDatos();
  };

  return (
    <div className="global" >
      <header>

      </header>
      {errorMessage &&        <div className="error-message2">
        {errorMessage}</div>}
      <div className="card">
      <img src={require("./Componentes/Imagenes/logoerlan.png")} className="loginlogo" style={{margin:'auto', width:'100%', height:'250px !important',padding:'15px 50px'}}/>
      <h2 style={{width:'100%' , fontSize:'13px' , textAlign:'center', padding:'10px' , backgroundColor:'#02a012',color:'white',fontWeight:'bold'}} > <img src={IconoLogo}></img> REGISTRO DE SUPERVISION DE CONTROL</h2>

        <form onSubmit={handleSubmit}  class="form card">
          <div class="card_header">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="23" height="23">
              <path fill="none" d="M0 0h24v24H0z"></path>
              <path fill="#02a012" d="M4 15h2v5h12V4H6v5H4V3a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-6zm6-4V8l5 4-5 4v-3H2v-2h8z"></path>
            </svg>
            <h1 class="form_heading">Inicio de Sesión</h1>
          </div>
          <div class="field">
          <label className="label">
            DNI:
          </label>
          <input
            className="input"
              type="text"
              value={dni}
              onChange={(e) => setDni(e.target.value)}
              autocomplete="username"

            />
          </div>
          <div class="field">
          <label className="label">
            Clave:
          </label>
          <input
              className="input"
              type="password"
              value={clave}
              onChange={(e) => setClave(e.target.value)}
              autocomplete="current-password"
            />
          </div>
          <div class="field">
            <button type="submit" className="botonlogin">Ingresar</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default App;